@import "variables";

.page-title {

}

.el-dialog {
    @media screen and (max-width: $--sm - 1) {
        width: 100% !important;
        border-radius: 0;
    }

    .el-dialog__header {
        padding-right: 35px;
    }

    @media (max-width: $--xs - 1) {
        .el-form-item__label {
            width: auto !important;
        }
        .el-form-item__content {
            clear: both;
            margin-left: 0 !important;
        }
    }
}

.el-table {
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    flex: 1;
    width: 100%;
    max-width: 100%;
    background-color: #FFFFFF;
    font-size: 13px;
    color: #606266;
}

.el-button--warning {
    color: #FFFFFF;
    background-color: #F99D1C;
    border-color: #F99D1C;
}
